import {SessionContext} from "components/providers/session";
import {useContext} from "react";

const currencyFormatters = {};
const currencySymbols = {};

export default function formatPrice(amount) {
	const session = useContext(SessionContext);
	const languageCode = session.config.language.languageCode;
	const currency = typeof session.currency.isoCode !== 'undefined' ? session.currency.isoCode : session.config.currency;

	if (!currency) {
		return amount;
	}

	function isFraction(number) {
		if (currency === 'USD') {
			return true
		}
		return number % 1 !== 0;
	}

	const amountFraction = isFraction(amount);
	const cacheKey = `${currency}-${languageCode}-${amountFraction}`;
	if (currencyFormatters[cacheKey]) {
		return currencyFormatters[cacheKey].format(amount);
	}

	const formatter = Intl.NumberFormat(languageCode, {
		style: 'currency',
		currency: currency,
		minimumFractionDigits: amountFraction,
		maximumFractionDigits: 2
	});

	currencyFormatters[cacheKey] = formatter;
	return formatter.format(amount);
}

export function getCurrencySymbol() {
	const session = useContext(SessionContext);

	const languageCode = session.config.language.languageCode;
	const currency = typeof session.currency.isoCode !== 'undefined' ? session.currency.isoCode : session.config.currency;

	if (!currency) {
		return '€';
	}

	const cacheKey = `${currency}`;
	if (currencySymbols[cacheKey]) {
		return currencySymbols[cacheKey];
	}

	const symbol = new Intl.NumberFormat(languageCode, { style: 'currency', currency })
		.formatToParts(1)
		.find(x => x.type === "currency")
		.value;

	currencySymbols[cacheKey] = symbol;
	return symbol;
}
